const initState = () => ({
  params:{
    hs: null,
    pqGuid: null,
  }
})

export const state = initState
export const mutations = {
  setHs(state, {hs}){
      state.params.hs = hs;
  },
  setPQGuid(state, {pqGuid}){
      state.params.pqGuid = pqGuid
  }

}
export const getters = {
  getUrlParams(state){
    return state.params;
  },
  getHsParam(state){
    return state.params.hs;
  },
  getPQGuidParam(state){
    return state.params.pqGuid;
  }
}
export const actions = {

}
